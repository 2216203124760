import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import "../styles/modal.css";
import tw from 'twin.macro'
import {
  ButtonStyle,
  CopyStyle,
  SubheadSMStyle,
} from '../components/UI'
import { setUtmParams, areUtmParamsAvailable } from '../utils';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const GOOGLE_SHEET_URL = process.env.GATSBY_GOOGLE_SHEET_STORAGE_URL;
const CUSTOMER_IO_API_KEY = process.env.GATSBY_CUSTOMER_IO_API_KEY;

const AppModal = ({ setIsOpen, showThankyouModal, jobDetails }) => {
  const {
    formState: { errors },
  } = useForm()
  const [formSubmitted, setFormSubmitted] = useState(false)
  const topForm = useForm()
  const bottomForm = useForm()
  const CUSTOMER_IO_FORM_ID = 'fb18da4f7b0b441';

  const dispatch = useDispatch();
  const utmParams = useSelector((state) => state.utmParams);

  useEffect(() => {
    if (!areUtmParamsAvailable(utmParams)) {
      dispatch({ type: "SAVE_UTM_PARAMS", payload: setUtmParams() });
    }
  }, [dispatch])

  const onSubmit = async (data) => {
    const params = {
      utmParams: JSON.stringify(utmParams),
      name: data.name,
      email: data.email,
      sheetName: 'Apply now form',
    }

    const formData = new FormData();
    // To make form data of the form object
    Object.keys(params).forEach((key) => {
      formData.append(key, params[key])
    })

    const options = {
      method: 'POST',
      body: formData,
    }

    try {
      // Google Sheets API call
      const response = await fetch(GOOGLE_SHEET_URL, options);
      // Customer.io API call
      const response2 = await fetch(
        `https://track.customer.io/api/v1/forms/${CUSTOMER_IO_FORM_ID}/submit`,
        {
          ...options,
          body: JSON.stringify({ data: params }),
          headers: {
            'Authorization': `Basic ${CUSTOMER_IO_API_KEY}`,
            'Content-Type': 'application/json'
          }
        }
      );
      // Convert response to json
      const result = await response.json();

      if (response2.status === 204 && result.result === 'success' && !formSubmitted) {
        setFormSubmitted(true);

        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'Applied on Job',
            eventLabel: 'Apply Now',
            field: 'Job',
            jobTitle: jobDetails?.title
          });
        }
      }
    } catch (err) {
      console.log(err);
      alert('There was an error submitting the form. Please try again later.');
    }

    setIsOpen(false)
    showThankyouModal(true)
  }

  return (
    <>
      <div className='darkBG' onClick={() => setIsOpen(false)} />
      <div className='centered'>
        <div className='modal'>
          <div className='modalHeader'>
            <span className='close-modal' onClick={() => setIsOpen(false)}>X</span>
            <h5 className='heading-modal'>Send me the app</h5>
          </div>

          <div className='modal-container'>
            {/* Are you sure you want to delete the item? */}
            <form
              onSubmit={topForm.handleSubmit(onSubmit)}
            // tw="grid grid-cols-1 lg:(grid-cols-[1fr repeat(12, calc(1024px/12)) 1fr] relative)"
            >
              <div css={[tw``]}>
                <div css={[formSubmitted && tw`invisible`]}>
                  {/* Name */}
                  <div tw="mb-10">
                    <label css={[SubheadSMStyle, tw`block mb-3.5 text-black`]} htmlFor="Name">
                      Full Name*
                    </label>
                    <div className="input-group">
                      <input
                        css={[CopyStyle, tw`border border-black w-full px-7 py-7 text-black`]}
                        type="text"
                        id="Name"
                        name="Name"
                        placeholder="Type your name"
                        {...topForm.register('name', { required: true })}
                      />
                      {topForm.formState.errors.name && (
                        <span css={[CopyStyle, tw`text-red-500`]}>
                          This field is required
                        </span>
                      )}
                    </div>
                  </div>
                  {/* Email */}
                  <div tw="mb-10">
                    <label css={[SubheadSMStyle, tw`block mb-3.5 text-black`]} htmlFor="Email">
                      Email*
                    </label>
                    <div className="input-group">
                      <input
                        css={[CopyStyle, tw`border border-black w-full px-7 py-7 text-black`]}
                        type="email"
                        id="Email"
                        name="Email"
                        placeholder="Type your email"
                        {...topForm.register('email', {
                          required: true,
                          pattern:
                            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        })}
                      />
                      {topForm.formState.errors.email && (
                        <span css={[CopyStyle, tw`text-red-500`]}>
                          This field is required
                        </span>
                      )}
                    </div>
                  </div>
                  {/* Submit */}
                  <div>
                    <input
                      type="submit"
                      value="Submit"
                      css={[
                        ButtonStyle,
                        tw`bg-[#FF4D00] text-white inline-block py-7 px-8 w-full text-center border-[#FF4D00] border-[1.5px] cursor-pointer lg:(px-16 w-auto)`,
                        tw`transition-all transition-duration[0.2s] transition-timing-function[cubic-bezier(0.645,0.045,0.355,1)] hover:(text-[#202C3A] bg-white)`,
                      ]}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div >
    </>
  );
};

export default AppModal;